import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import { getProfileDetail } from '../../../redux/reducers/profileSlice'
import './finaloutput.css'
import { ReactComponent as Group4Icon } from '../../../images/Group4.svg';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import dayjs from 'dayjs'
import FavoriteIcon from '@mui/icons-material/Favorite';
import CountdownTimer from './CountdownTimer'
import EventSlider from './EventSlider'
import ReactPlayer from 'react-player'
import { snackbarContext } from '../../helpers/Snakbar'
import { getEventsById } from '../../../redux/reducers/eventSlice'
import { TabTitle } from '../../helpers/GeneralFunctions'
import FSharing from './FSharing'
import Loader from '../../helpers/Loader'
import Envelope from '../../helpers/Envelope'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import {Helmet} from "react-helmet";
const FinalOutput = () => {

  const setStateSnackbarContext = React.useContext(snackbarContext)
  const { weddingData: data, loading } = useSelector((state) => state.profile)
  const { events } = useSelector((state) => state.event)
  const [scrolledToEvents, setScrolledToEvents] = useState(false);
  const [values, setValues] = useState({
    bannerImage: "",
    brideName: "",
    groomName: "",
    hashtag: "",
    fontAlignment: "",
    weddingDate: "",
    fontColor: "",
    fontStyle: "",
    groomImage: "",
    groomFullName: "",
    groomParentDetail: "",
    brideImage: "",
    brideFullName: "",
    brideParentDetail: "",
    countDownDate: "",
    preWeddingLink: "",
    footerImage: "",
    specialMessage: "",
    instagramLink: "",
    facebookLink: "",
    theme: "",
    coverIcon: "",
    coverText: ""
  })

  const [temp, settemp] = useState(true)
  // const [bannerImageLoaded, setBannerImageLoaded] = useState(false);
  TabTitle(((values.groomName !== "Groom Name") && (values.BrideName !== "Bride Name")) ? `${values.groomName} & ${values.brideName} - Wedding Invitation` : "Aamantran Wedding Invitation")

  const params = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation();
  const id = params.username
  const queryParams = new URLSearchParams(location.search);
  const group = queryParams.get('group');
  useEffect(() => {
    dispatch(getProfileDetail({ id, navigate, setStateSnackbarContext }))
    dispatch(getEventsById({ id, group }))
  }, [id])


  useEffect(() => {
    setValues({ ...data })
  }, [data])

  const formatDate = (inputDate) => {
    const date = dayjs(inputDate);
    const formattedDate = date.format('DD MMMM YYYY');
    return formattedDate
  };
  const internalCss = `
    .banner-date::before,
.banner-date::after {
    background-color:${values.fontColor}
}
 .custom-icon path {
    fill:${values.fontColor} ; 
  }
    `
  const scrollToEvents = () => {
    const eventsSection = document.getElementById('events-section');
    if (eventsSection) {
      const eventsSectionTop = eventsSection.offsetTop;
      window.scrollTo({ top: eventsSectionTop, behavior: 'smooth' });
    }
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  const getScrollButtonMessage = () => {
    return scrolledToEvents ? "Scroll to Top" : "Scroll to Events";
  };
  // useEffect(() => {
  //   if (values.bannerImage) {
  //     const img = new Image();
  //     img.src = values.bannerImage.url; // Preload the banner image URL
  //   }
  // }, [values.bannerImage]);

  useEffect(() => {
    const handleScroll = () => {
      const eventsSection = document.getElementById('events-section');

      if (eventsSection) {
        const scrollPosition = window.scrollY || window.pageYOffset;
        const viewportHeight = window.innerHeight;
        const eventsSectionTop = eventsSection.offsetTop;
        const triggerOffset = 0.8; // Adjust this value based on your preference (0.8 means 80% of the viewport height)
        setScrolledToEvents(scrollPosition >= eventsSectionTop - triggerOffset * viewportHeight);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  if (loading) {
    return <Loader />
  }

  if (temp) {
    return <Envelope settemp={settemp} theme={values.theme} coverIcon={values.coverIcon} coverText={values.coverText} />
  }

  return (
    <>
    <Helmet>
      <title>hello</title>
      <meta property='og:image' content="https://res.cloudinary.com/dtg40vnf7/image/upload/c_thumb,w_200,g_face/v1727070104/user_h4njsjp3q/jnoqdu1vfi1pvrrolhk3.jpg"   />
    </Helmet>
      <>
        <style>{internalCss}</style>
        <div className={`main-details-container ${values.theme}`}>

          <div className='banner'>
          <LazyLoadImage 
  src={values.bannerImage ? values.bannerImage.url : ""} 
  className='banner-img'
  srcSet={`
    ${values.bannerImage ? values.bannerImage.url.replace('/upload/', '/upload/w_320/') : ''} 320w,
    ${values.bannerImage ? values.bannerImage.url.replace('/upload/', '/upload/w_640/') : ''} 640w,
    ${values.bannerImage ? values.bannerImage.url.replace('/upload/', '/upload/w_1080/') : ''} 1080w,
    ${values.bannerImage ? values.bannerImage.url.replace('/upload/', '/upload/w_1920/') : ''} 1920w
  `}
  sizes="(max-width: 320px) 320px, 
         (max-width: 640px) 640px, 
         (max-width: 1080px) 1080px, 
         1920px"
/>
            <div className={`banner-details banner-${values.fontAlignment}`} style={{ color: values.fontColor, fontFamily: values.fontStyle }} >
              <h3>{values.groomName}</h3>
              <Group4Icon className="custom-icon" />
              <h3>{values.brideName}</h3>
              <div className="banner-date"  >
                {values.weddingDate ? formatDate(values.weddingDate) : "DD-MM-YYYY"}
              </div>
              {values.hashtag && <div className='banner-hastag'>{values.hashtag === "hashtag" ? "" : `# ${values.hashtag}`}</div>}
            </div>
          </div>
          <h1 className='main-heading'>Bride and Groom</h1>
          <div className="bg-container">
            <div className="bg-card">
              <img src={values.groomImage ? values.groomImage.url : ''} alt="" loading='lazy' className='bg-card-img'
              srcSet={`
    ${values.groomImage ? values.groomImage.url.replace('/upload/', '/upload/w_320/') : ''} 320w,
    ${values.groomImage ? values.groomImage.url.replace('/upload/', '/upload/w_640/') : ''} 640w,
    ${values.groomImage ? values.groomImage.url.replace('/upload/', '/upload/w_1080/') : ''} 1080w,
    ${values.groomImage ? values.groomImage.url.replace('/upload/', '/upload/w_1920/') : ''} 1920w
  `}
  sizes="(max-width: 320px) 320px, 
         (max-width: 640px) 640px, 
         (max-width: 1080px) 1080px, 
         1920px"
               />
              {/* <LazyLoadImage 
              src={values.groomImage ? values.groomImage.url : ''} 
              className='bg-card-img' 
              srcSet={`
    ${values.groomImage ? values.groomImage.url.replace('/upload/', '/upload/w_320/') : ''} 320w,
    ${values.groomImage ? values.groomImage.url.replace('/upload/', '/upload/w_640/') : ''} 640w,
    ${values.groomImage ? values.groomImage.url.replace('/upload/', '/upload/w_1080/') : ''} 1080w,
    ${values.groomImage ? values.groomImage.url.replace('/upload/', '/upload/w_1920/') : ''} 1920w
  `}
  sizes="(max-width: 320px) 320px, 
         (max-width: 640px) 640px, 
         (max-width: 1080px) 1080px, 
         1920px"
                /> */}

              <h3>({values.groomFullName})</h3>
              {values.groomParentDetail !== "Groom Parent Detail" && values.groomParentDetail !== "" && (
                <h4>{`(S/O ${values.groomParentDetail})`}</h4>)}
            </div>
            <FavoriteIcon className='bg-icon' />
            <div className="bg-card">
              <img src={values.brideImage ? values.brideImage.url : ''} alt="" loading='lazy' className='bg-card-img'  
               srcSet={`
    ${values.brideImage ? values.brideImage.url.replace('/upload/', '/upload/w_320/') : ''} 320w,
    ${values.brideImage ? values.brideImage.url.replace('/upload/', '/upload/w_640/') : ''} 640w,
    ${values.brideImage ? values.brideImage.url.replace('/upload/', '/upload/w_1080/') : ''} 1080w,
    ${values.brideImage ? values.brideImage.url.replace('/upload/', '/upload/w_1920/') : ''} 1920w
  `}
  sizes="(max-width: 320px) 320px, 
         (max-width: 640px) 640px, 
         (max-width: 1080px) 1080px, 
         1920px"
              
               />
              {/* <LazyLoadImage 
              src={values.brideImage ? values.brideImage.url : ''} 
               className='bg-card-img'
               srcSet={`
    ${values.brideImage ? values.brideImage.url.replace('/upload/', '/upload/w_320/') : ''} 320w,
    ${values.brideImage ? values.brideImage.url.replace('/upload/', '/upload/w_640/') : ''} 640w,
    ${values.brideImage ? values.brideImage.url.replace('/upload/', '/upload/w_1080/') : ''} 1080w,
    ${values.brideImage ? values.brideImage.url.replace('/upload/', '/upload/w_1920/') : ''} 1920w
  `}
  sizes="(max-width: 320px) 320px, 
         (max-width: 640px) 640px, 
         (max-width: 1080px) 1080px, 
         1920px"
                /> */}
              <h3>({values.brideFullName})</h3>
              {values.brideParentDetail !== "Bride Parent Detail" && values.brideParentDetail !== "" && (
                <h4>{`(D/O ${values.brideParentDetail})`}</h4>)}
            </div>
          </div>

          <div className="sepration"> <Group4Icon className="sepration-icon" /></div>
          <div className="countdown-video-div">
            {values.countDownDate &&
              <div className='countDownDiv'>
                <h3>The wait is over, Countdown Begins</h3>
                <CountdownTimer targetDate={values.countDownDate} />
                <span>Days To Go</span>

              </div>}
            {values.preWeddingLink &&
              <>

                <ReactPlayer url={values.preWeddingLink}
                  className="video-container"
                  playing={true}
                  playsinline
                  controls={true}
                  muted={true}
                  volume={1}
                />
              </>
            }
          </div>
          <EventSlider events={events} />
          <div className="sepration mt-4"> <Group4Icon className="sepration-icon" /></div>
          {values.footerImage && values.footerImage.url && <div className='footer-image-final'>

            <img src={values.footerImage ? values.footerImage.url : ''} alt="" loading='lazy' className='memorable-pic'
                     srcSet={`
    ${values.footerImage ? values.footerImage.url.replace('/upload/', '/upload/w_320/') : ''} 320w,
    ${values.footerImage ? values.footerImage.url.replace('/upload/', '/upload/w_640/') : ''} 640w,
    ${values.footerImage ? values.footerImage.url.replace('/upload/', '/upload/w_1080/') : ''} 1080w,
    ${values.footerImage ? values.footerImage.url.replace('/upload/', '/upload/w_1920/') : ''} 1920w
  `}
  sizes="(max-width: 320px) 320px, 
         (max-width: 640px) 640px, 
         (max-width: 1080px) 1080px, 
         1920px"
             />
            {/* <LazyLoadImage src={values.footerImage ? values.footerImage.url : ''} className='memorable-pic' 
              srcSet={`
    ${values.footerImage ? values.footerImage.url.replace('/upload/', '/upload/w_320/') : ''} 320w,
    ${values.footerImage ? values.footerImage.url.replace('/upload/', '/upload/w_640/') : ''} 640w,
    ${values.footerImage ? values.footerImage.url.replace('/upload/', '/upload/w_1080/') : ''} 1080w,
    ${values.footerImage ? values.footerImage.url.replace('/upload/', '/upload/w_1920/') : ''} 1920w
  `}
  sizes="(max-width: 320px) 320px, 
         (max-width: 640px) 640px, 
         (max-width: 1080px) 1080px, 
         1920px"
             /> */}
          </div>}
          {values.specialMessage && <div className='special-message'>
            <h3>“ {values.specialMessage} ”</h3>
            <h4>By - {values.groomName} & {values.brideName}</h4>
          </div>}

          <div className="social-media">
            {values.instagramLink || values.facebookLink ? (
              <>
                <h2 className='main-heading'>Do visit Our Social Media</h2>
                <div className="icons-div">
                  {values.instagramLink && (
                    <a href={values.instagramLink} target="_blank" rel="noopener noreferrer">
                      <i className="fa-brands fa-instagram social-icon"></i>
                    </a>
                  )}
                  {values.facebookLink && (
                    <a href={values.facebookLink} target="_blank" rel="noopener noreferrer">
                      <i class="fa-brands fa-facebook social-icon"></i>
                    </a>
                  )}
                </div>
              </>
            ) : null}
          </div>

          <FSharing groomName={values.groomName} brideName={values.brideName} weddingDate={values.weddingDate} />

          <div className="footer">
            <h3>Created with ❤️ at Aamantran</h3>
          </div>
          <div className="scroll-button" onClick={scrolledToEvents ? scrollToTop : scrollToEvents}>
            {scrolledToEvents ? <ArrowUpwardIcon className='up-down-arrow' /> : <ArrowDownwardIcon className='up-down-arrow' />}
            <div className="scroll-button-message">{getScrollButtonMessage()}</div>
          </div>
        </div>
      </>
    </>

  )
}

export default FinalOutput